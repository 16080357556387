import React from "react";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { useTranslation } from "react-i18next";

const Portfolio = ({ classicHeader, darkTheme }) => {
  const { t } = useTranslation();

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              {t("recruitment")}
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {t("careers")}
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end */}

          {/* Recruitment Cards */}
          <div className="row">
            {/* Aerospace Engineering Card */}
            <div className="col-md-6 mb-4 d-flex align-items-stretch">
              <div className={"card " + (darkTheme ? "bg-dark" : "bg-light") + " h-100"}>
                <div className="card-body text-center d-flex flex-column">
                  <h5 className="card-title fw-bold">{t("aerospaceTitle")}</h5>
                  <p className="card-text flex-grow-1">{t("aerospaceDescription1")}</p>
                  <p className="card-text flex-grow-1">{t("aerospaceDescription2")}</p>
                  <button className="btn btn-primary mt-auto" onClick={scrollToContact}>
                    {t("contactUs")}
                  </button>
                </div>
              </div>
            </div>

            {/* Applied Electromagnetics and Electronics Card */}
            <div className="col-md-6 mb-4 d-flex align-items-stretch">
              <div className={"card " + (darkTheme ? "bg-dark" : "bg-light") + " h-100"}>
                <div className="card-body text-center d-flex flex-column">
                  <h5 className="card-title fw-bold">{t("electronicsTitle")}</h5>
                  <p className="card-text flex-grow-1">{t("electronicsDescription1")}</p>
                  <p className="card-text flex-grow-1">{t("electronicsDescription2")}</p>
                  <button className="btn btn-primary mt-auto" onClick={scrollToContact}>
                    {t("contactUs")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal darkTheme={darkTheme}></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
